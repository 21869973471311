<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-0"
      >
        <div class="w-100 h-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="sideImg"
            alt="Login V2"
            class="w-100 h-100"
          />
        </div>
      </b-col>
      <b-col
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-0 text-center"
          >
            <b-img
              fluid
              :src="logoImg"
            />
          </b-card-title>
          <b-card-text class="mb-1">
            <div class="d-flex flex-column text-center title-container">
              <span>Welcome to CMS</span>
              <a class="text-info">www.akarahospitality.com</a>
            </div>
          </b-card-text>
          <validation-observer ref="form">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email address"
                  rules="required|email"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="login-email"
                      v-model="email"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="Email"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LockIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="login-password"
                      autocomplete="true"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="forgot-password">
                <b-link 
                  class="text-info"
                  :to="{ name:'auth-forgot-password' }"
                >
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <b-button
                type="submit"
                variant="primary"
                block
                pill
                :disabled="isDisabledButton"
                @click="validationForm"
              >
                Login
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="text-center mt-1 text-secondary">
            <span>If you have any issue. Please contact 097-006-0592</span>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupPrepend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from './useAuth'
import authStoreModule from './authStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email: '',
      password: '',
      sideImg: require('@/assets/images/pages/login-v3.svg'),
      logoImg: require('@/assets/images/logo/logo.svg'),
      required,
      email,
      loading: false,
    }
  },
  computed: {
    isDisabledButton() {
      return !this.email || !this.password || this.loading
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {    
    validationForm() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) return

        this.loading = true
        // login
        const { id, status: loginStatus } = await this.login({
          email: this.email,
          password: this.password,
        })
        if (loginStatus !== 200) {
          this.makeToast()
          this.loading = false
          return;
        }

        // get user infomation
        const { data: userData, status: userInfoStatus } = await this.fetchUser(id)
        if (userInfoStatus !== 200) {
          this.makeToast()
          this.loading = false
          return;
        }

        // for adminstrator
        const ability = [{
          action: 'manage',
          subject: 'all',
        }]
        this.$ability.update(ability)
        localStorage.setItem('userData', JSON.stringify({...userData, ability }))
        this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
        this.loading = false
      })
    },
    makeToast() {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Login Failed.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
  setup() {
    const AUTH_APP_STORE_MODULE_NAME = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP_STORE_MODULE_NAME)) store.registerModule(AUTH_APP_STORE_MODULE_NAME, authStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTH_APP_STORE_MODULE_NAME)) store.unregisterModule(AUTH_APP_STORE_MODULE_NAME)
    })

    const {
      toast,
      login,
      fetchUser,
    } = useAuth()
    
    return {
      toast,
      login,
      fetchUser,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.forgot-password {
  margin-top: -5px;
  margin-bottom: 30px;
}
.text-info {
  color: #0E76A8 !important;
}
.title-container {
  & span {
    font-size: 18px;
  }
}
</style>
